<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="1">
        <v-btn color="warning" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11" class="text-h5 font-weight-regular">
        Lavorazione viaggio di arrivo da {{ viaggioTreno.nodoPartenza | codice }} a {{ viaggioTreno.nodoArrivo | codice }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab v-for="convoglio in convogli" :key="convoglio.id">{{ convoglio.nome }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item v-for="convoglio in convogli" :key="convoglio.id">
               <ConvoyExecution :convoglio="convoglio" />         
          </v-tab-item>
        </v-tabs-items>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ToastMixin from "../../mixins/ToastMixin";
import ConvoyExecution from "../../components/misc/ConvoyExecution";

export default {
  name: "LavorazioneViaggioArrivo", 
  components: {
    ConvoyExecution
  },
  mixins: [ToastMixin],
  filters: {
    codice: function (value) {
      if (value) return value.codice;
    },
  },
  props: ["viaggioTrenoId"],
  data() {
    return {
      tab: null,
      viaggioTreno: {},
      editPanel: [0, 1, 2, 3],
      convogli: null
    };
  },
  created() {
    this.$eventBus.$on("convoyReload", async () => {
      await this.fetchData();
    });
  },
  async mounted() {
    await this.fetchData();
  },
  computed: {
    
  },
  methods: {
    async fetchData() {
      try {
        this.viaggioTreno = await this.$api.railData.getViaggioTrenoById(this.viaggioTrenoId);
        this.convogli = await this.$api.trainData.findAllConvogliByCarroConvoglioViaggioTreno(this.viaggioTrenoId);
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style scoped>
</style>
